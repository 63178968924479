import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getCategoryPath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import VideoThumb from "../components/videoThumb.js"
import { useFavicon } from "../hooks/useFavicon"
import BannerThumb from "../components/bannerThumb"
import BannerList from "../components/blocks/bannerList"
import Breadcrumbs from "../components/breadcrumbs"
import InfiniteScroll from "react-infinite-scroll-component"
import LoadingSpinner from "../components/loadingSpinner"

// const LocationsMap = loadable(
//   () => import("../components/blocks/locationMap"),
//   { ssr: false }
// )

const Page = ({ data: { page, videos, site } }) => {
  const [hasMore, setHasMore] = useState()
  const [items, setItems] = useState([])
  const sliceIntoChunks = (arr, chunkSize) => {
    const res = []
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize)
      res.push(chunk)
    }
    return res
  }

  const arrayTmp = sliceIntoChunks(videos.nodes, 8)

  const favicon = useFavicon().site.faviconMetaTags
  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getCategoryPath(page, locale.locale),
    }
  })

  useEffect(() => {
    if (items.length <= 0) {
      setItems(arrayTmp.slice(0, 3))
      setHasMore(true)
    }
  }, [])

  const fetchMoreData = () => {
    // console.log("fetchMoreData", items.length, arrayTmp.length)
    if (items.length >= arrayTmp.length) {
      setHasMore(false)
      return
    }

    setTimeout(() => {
      setItems(
        items.concat(
          arrayTmp.slice(
            items.length,
            items.length > arrayTmp.length - (items.length + 1)
              ? arrayTmp.length
              : items.length + 1
          )
        )
      )
    }, 1000)
  }

  return (
    <Layout
      pageSlug={page.channel.pageLink.slug}
      locale={page.locale}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>

      <Box>
        <Container>
          <Box sx={{ py: [4, 4, 6], pt: [10, 10, 6] }}>
            <Breadcrumbs page={page} />
          </Box>
          <Box>
            <Grid
              columns={["1fr", "1fr", page.banner ? ".55fr .45fr " : "1fr"]}
              gap={[3, 3, 9]}
              sx={{
                pb: [6, 6, 0],
                alignItems: "center",
              }}
            >
              <Box sx={{ pt: [0], pb: [0, 0, 6], textAlign: "left" }}>
                <Heading
                  as="h1"
                  variant="h2"
                  sx={{
                    fontWeight: "400",
                    fontSize: [6, 6, 8],
                    lineHeight: "1",
                    color: "primary",
                  }}
                >
                  {page.title}
                </Heading>
                <Text
                  as="div"
                  sx={{
                    p: {
                      color: "dark",
                      fontSize: [3, 5],
                      lineHeight: "1.25",
                      fontWeight: "400",
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: page.description }}
                />
              </Box>
              {page.banner && (
                <Box sx={{ maxWidth: "460px" }}>
                  <BannerThumb banner={page.banner} />
                </Box>
              )}
            </Grid>
          </Box>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <Flex
                sx={{
                  width: "100%",
                  height: "120px",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    opacity: 1,
                    zIndex: 1,
                    transition: "opacity .3s linear",
                    backgroundColor: "light",
                  }}
                >
                  <LoadingSpinner />
                </Box>
              </Flex>
            }
          >
            <Box sx={{ pb: [6, 8] }}>
              {items.map((videosArray, index) => {
                return (
                  <>
                    <Grid
                      columns={[1, 2, 3, 4]}
                      gap={["50px 8px", "50px 8px", "50px 8px", "50px 8px"]}
                    >
                      {videosArray.map(
                        video =>
                          page.slug === video.category.slug && (
                            <VideoThumb video={video} category={page.title} />
                          )
                      )}
                    </Grid>
                    {(index === 0 || items.length !== index + 1) && (
                      <BannerList
                        banners={page.bannersCategory}
                        channel={page.channel.pageLink.slug}
                      />
                    )}
                  </>
                )
              })}
            </Box>
          </InfiniteScroll>
        </Container>
      </Box>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query VideoCategoryQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsVideoCategory(id: { eq: $id }) {
      ...VideoCategoryPageDetails
    }
    videos: allDatoCmsVideo(
      filter: {
        category: { id: { eq: $id } }
        locale: { eq: $locale }
        slug: { ne: null }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        ...AllVideoSlugLocales
        slug
        title
        videoId
        videoLink
        locale
        duration
        meta {
          firstPublishedAt
        }
        thumbnail {
          desktop: gatsbyImageData(placeholder: BLURRED, width: 600)
          mobile: gatsbyImageData(
            placeholder: BLURRED

            imgixParams: { fit: "crop", ar: "16:10", h: "800" }
          )
        }
        speaker {
          id
          name
        }
        category {
          id
          title
          locale
          slug
          model {
            apiKey
          }
          channel {
            pageLink {
              id
              slug
            }
          }
        }
      }
    }
  }

  fragment VideoCategoryPageDetails on DatoCmsVideoCategory {
    id
    locale
    title
    slug
    description
    banner {
      id
      title
      image {
        gatsbyImageData(width: 1480, placeholder: BLURRED)
      }
      url
    }
    bannersCategory {
      id
      title
      image {
        url
        title
        alt
      }
      url
    }
    channel {
      id
      pageLink {
        id
        slug
      }
    }
    model {
      apiKey
    }
    ...AllVideoCategorySlugLocales
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    model {
      apiKey
    }
  }

  fragment AllVideoCategorySlugLocales on DatoCmsVideoCategory {
    _allSlugLocales {
      value
      locale
    }
  }
`
